import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import Button from 'eventtia-ui-components/lib/Button';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import intlLocales from '../../helpers/intlLocales';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.darkGrey.light,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    boxShadow: '0px 0px 34px #00000019',
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      height: '100%',
    },
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '280px',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
    },
  },

  productDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3, 2, 1),
    flex: '0 0 50%',
  },
  name: {
    fontWeight: 600,
  },
  price: {
    margin: theme.spacing(2, 0),
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  description: {
    lineHeight: 1.5,
    whiteSpace: 'pre-wrap',
  },
  readMore: {
    display: 'inline-block',
    fontWeight: '600',
    fontStyle: 'italic',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  productImageContainer: {
    width: '100%',
    alignSelf: 'stretch',
    backgroundColor: 'pink',
    borderRadius: '10px',
  },
  productImagewrapper: {
    width: '100%',
    borderRadius: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: '200px',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50%',
  },
}));

const SponsorProduct = ({ product, currency }) => {
  const {
    name,
    price,
    description,
    purchaseLink,
    image: {
      filename,
      medium,
    },
  } = product;
  const classes = useStyles();
  const { t } = useTranslation('sponsors');
  const [open, setOpen] = useState(false);
  const { locale } = useParams();

  const urlFixed = (url) => {
    if (!/^https?:\/\//i.test(url)) return `http://${url}`;
    return url;
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.flexContainer}>
        <div className={classes.productDetailsContainer}>
          <div>
            <Typography variant="body2" className={classes.name}>
              {name}
            </Typography>
            <Typography variant="body2" className={classes.price}>
              {!!price && `${currency} ${new Intl.NumberFormat(intlLocales[locale]).format(price)}`}
            </Typography>
            <Typography paragraph variant="caption" className={classes.description}>
              {description.length > 350 && !open ? (
                <>
                  {description.slice(0, 350)}
                  <span className={classes.readMore} onClick={() => setOpen(!open)} aria-hidden="true">{`...${t('data.actions.readMore')}`}</span>
                </>
              ) : (description)}
            </Typography>
          </div>
          <Button variant="primary" target="_blank" href={urlFixed(purchaseLink)}>{t('data.actions.purchase')}</Button>
        </div>
        <div className={classes.productImagewrapper}>
          {filename && (
            <div className={classes.image} style={{ backgroundImage: `url('${medium}')` }} />
          )}
        </div>
      </div>
    </Paper>
  );
};

SponsorProduct.propTypes = {
  product: CustomPropTypes.product.isRequired,
  currency: PropTypes.string.isRequired,
};

export default SponsorProduct;

import React, {
  useState, useMemo, useEffect,
} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Loader from 'eventtia-ui-components/lib/Loader';
import callApi from '../../actions/callApi';
import { setActiveAttendee } from '../../actions/app';
import PersonCard from '../../components/PersonCard';
import PeopleShow from '../PeopleShow';
import {
  getModuleByType,
  getCurrentAttendee,
  getCurrentParticipant,
  getModuleNames,
  getCurrentPerson,
} from '../../helpers/getters';
import InfiniteScroll from '../../components/InfiniteScroll';
import FilterActions from '../../components/FilterActions';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import getDistinctIds from '../../helpers/getDistinctIds';
import { showInEventTimezone } from '../../helpers/dates';
import SubpageTitle from '../../components/SubpageTitle';
import TabletExitButton from '../../components/TabletExitButton';
import useEventUriHack from '../../hooks/hacks/useEventUriHack';

const useStyles = makeStyles((theme) => ({
  scroll: {
    minWidth: 320,
    maxHeight: '100%',
    padding: theme.spacing(
      theme.subpageDrawer.paddings.desktop.vertical,
      theme.subpageDrawer.paddings.desktop.horizontal
    ),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(
        theme.subpageDrawer.paddings.mobile.vertical,
        theme.subpageDrawer.paddings.mobile.horizontal
      ),
    },
  },
  container: {
    margin: 0,
    padding: 0,
    overflowY: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  showContainer: {
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(
      theme.subpageDrawer.paddings.desktop.vertical,
      theme.subpageDrawer.paddings.desktop.horizontal
    ),
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(
        theme.subpageDrawer.paddings.mobile.vertical,
        theme.subpageDrawer.paddings.mobile.horizontal
      ),
    },
  },
  moduleName: {
    padding: theme.spacing(1.5, 0),
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
  },
  moduleDescription: {
    color: theme.palette.darkGrey.light,
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(3, 0),
    color: theme.palette.primary.main,
  },
  filters: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    alignItems: 'flex-end',
  },
  header: {
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      position: 'sticky',
      backgroundColor: theme.palette.background.light,
      zIndex: 1,
      top: theme.spacing(-theme.subpageDrawer.paddings.desktop.vertical),
      padding: theme.spacing(
        theme.subpageDrawer.paddings.desktop.vertical,
        theme.subpageDrawer.paddings.desktop.horizontal,
        0
      ),
      margin: theme.spacing(
        -theme.subpageDrawer.paddings.desktop.vertical,
        -theme.subpageDrawer.paddings.desktop.horizontal,
        theme.subpageDrawer.paddings.desktop.vertical
      ),
    },
  },
  singleAttendeeLoader: {
    zIndex: 2,
  },
}));

const People = ({
  entities,
  isFetchingAttendees,
  attendeesError,
  isFetchingSingleAttendee,
  singleAttendeeError,
  links,
  attendeesOrder,
  attendees,
  businessConferences,
  businessConferenceParticipants,
  attendeeTypeCustomFields,
  ratings,
  denyList,
  recommendations,
  callApi: dispatchCallApi,
  attendeeTypes,
  businessConferenceParticipantTypes: participantTypes,
  appSettings,
  activeAttendeeId,
  setActiveAttendee: dispatchSetActiveAttendee,
  scrollToTop,
  broadcasts,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('attendees');
  const { eventUri } = useParams();
  const sortAttendeesByLastName = useEventUriHack('sortAttendeesByLastName');
  const [sort, setSort] = useState(!sortAttendeesByLastName ? '' : 'last_name asc');
  const moduleTitle = getModuleNames(appSettings, 'Attendees');

  const currentAttendee = getCurrentAttendee(entities);
  const currentParticipant = getCurrentParticipant(entities);

  const [settings] = Object.values(appSettings);
  const attendeesModule = getModuleByType(settings.modules, 'Attendees');
  const chatEnabledFor = attendeesModule?.customParams?.chatEnabledParticipantTypes || [];
  const showAllAttendees = (
    attendeesModule?.customParams?.attendeesListEnabled || []
  ).includes(currentAttendee.attendeeType.id);
  const offsetToUtc = !showInEventTimezone(settings);

  const [conference] = Object.values(businessConferences);
  const { automaticScheduling, blacklistEnabled, showOnlyConfirmed = true } = conference || {};

  const currentParticipantId = currentParticipant?.id;
  const businessConferenceId = currentParticipant?.businessConference?.id;
  const currentParticipantType = participantTypes[currentParticipant?.participantTypeId];
  const canRequestMeetingTo = currentParticipantType?.canRequestMeetingToAttendeeTypes;

  const order = useMemo(() => {
    const currentPersonId = getCurrentAttendee(entities)?.id;
    return attendeesOrder?.filter((id) => id !== currentPersonId);
  }, [attendeesOrder, entities]);

  const stringAttendeeTypesParam = useMemo(() => {
    if (showAllAttendees) return '';
    if (!businessConferenceId || !canRequestMeetingTo) return chatEnabledFor.join(',');
    return getDistinctIds(!chatEnabledFor.includes(currentAttendee.attendeeType.id),
      canRequestMeetingTo, chatEnabledFor).join(',');
  }, [
    businessConferenceId, canRequestMeetingTo,
    chatEnabledFor, currentAttendee.attendeeType.id, showAllAttendees,
  ]);

  const fetchParams = useMemo(() => {
    const values = {
      eventUri,
      businessConferenceId,
      sort,
      offsetToUtc,
      showOnlyConfirmed,
    };
    if (stringAttendeeTypesParam) values.attendeeTypes = stringAttendeeTypesParam;
    return values;
  }, [
    stringAttendeeTypesParam, eventUri, businessConferenceId, sort, offsetToUtc, showOnlyConfirmed,
  ]);

  useEffect(() => {
    dispatchCallApi('attendees', fetchParams);
  }, [dispatchCallApi, fetchParams]);

  useEffect(() => {
    if (currentParticipantId) {
      dispatchCallApi('recommendedParticipants', {
        eventUri,
        businessConferenceId,
        currentParticipantId,
      });
      if (automaticScheduling) {
        dispatchCallApi('ratings', {
          eventUri,
          businessConferenceId,
        });
        if (blacklistEnabled) dispatchCallApi('denyList', {
          eventUri,
          businessConferenceId,
        });
      }
    }
  }, [
    dispatchCallApi, eventUri, businessConferenceId, currentParticipantId,
    automaticScheduling, blacklistEnabled,
  ]);

  const activeAttendee = attendees[activeAttendeeId] && getCurrentPerson(
    attendees[activeAttendeeId], businessConferenceParticipants, attendeeTypes,
    attendeeTypeCustomFields
  );
  const shouldFetchSingleAttendee = !isFetchingAttendees && !isFetchingSingleAttendee
    && !singleAttendeeError && !attendeesError && activeAttendeeId && Number(activeAttendeeId)
    && !activeAttendee;
  useEffect(() => {
    if (shouldFetchSingleAttendee) dispatchCallApi('singleAttendee', { eventUri, id: activeAttendeeId, offsetToUtc });
  }, [
    dispatchCallApi, eventUri, activeAttendeeId, shouldFetchSingleAttendee, offsetToUtc,
  ]);

  useEffect(() => {
    dispatchCallApi('attendeeTypes', { eventUri });
  }, [dispatchCallApi, eventUri]);

  const fetchNextPageIfExists = () => {
    const { next } = links;
    if (!activeAttendeeId && next) dispatchCallApi(
      'attendees',
      fetchParams,
      next
    );
  };

  return (
    <>
      {!activeAttendeeId && (
        <TabletExitButton />
      )}
      <Loader
        className={classes.singleAttendeeLoader}
        variant="absolute"
        loading={isFetchingSingleAttendee}
      />
      {activeAttendee && (
        <div className={classes.showContainer}>
          <PeopleShow
            currentAttendee={currentAttendee}
            currentParticipant={currentParticipant}
            person={activeAttendee}
            attendeeTypeCustomFields={attendeeTypeCustomFields}
            scrollToTop={scrollToTop}
            broadcasts={broadcasts}
          />
        </div>
      )}
      <Container className={classes.container}>
        <InfiniteScroll
          loading={isFetchingAttendees}
          error={attendeesError}
          fetchNextPageIfExists={fetchNextPageIfExists}
          className={classes.scroll}
        >
          <div>
            <div className={classes.header}>
              <SubpageTitle text={moduleTitle} />
              <Typography className={classes.moduleDescription} variant="body1">
                {t('title.description')}
              </Typography>
              <FilterActions
                entity="attendees"
                fetchParams={fetchParams}
                sort={sort}
                setSort={setSort}
              />
            </div>
            <Grid container spacing={2}>
              {order?.map((attendeeId) => {
                const currentPerson = getCurrentPerson(
                  attendees[attendeeId], businessConferenceParticipants, attendeeTypes,
                  attendeeTypeCustomFields, ratings, denyList, recommendations
                );
                return (
                  <Grid item xs={12} sm={12} md={4} lg={3} key={attendeeId}>
                    <PersonCard
                      person={currentPerson}
                      onBlockClick={() => dispatchSetActiveAttendee(attendeeId)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </InfiniteScroll>
      </Container>
    </>
  );
};

People.propTypes = {
  callApi: PropTypes.func.isRequired,
  entities: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
  attendees: PropTypes.objectOf(
    CustomPropTypes.attendee
  ),
  businessConferences: PropTypes.objectOf(
    CustomPropTypes.conference
  ),
  businessConferenceParticipants: PropTypes.objectOf(
    CustomPropTypes.participant
  ),
  attendeeTypeCustomFields: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  isFetchingAttendees: PropTypes.bool,
  isFetchingSingleAttendee: PropTypes.bool,
  attendeeTypes: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  businessConferenceParticipantTypes: PropTypes.objectOf(
    CustomPropTypes.participantType
  ),
  attendeesError: PropTypes.string,
  singleAttendeeError: PropTypes.string,
  attendeesOrder: PropTypes.arrayOf(
    PropTypes.string
  ),
  links: PropTypes.shape({
    next: PropTypes.string,
  }),
  appSettings: CustomPropTypes.appSettings.isRequired,
  activeAttendeeId: PropTypes.string,
  setActiveAttendee: PropTypes.func.isRequired,
  ratings: PropTypes.objectOf(CustomPropTypes.rating),
  denyList: PropTypes.objectOf(CustomPropTypes.deniedParticipant),
  recommendations: PropTypes.arrayOf(CustomPropTypes.recommendation),
  scrollToTop: PropTypes.func.isRequired,
  broadcasts: PropTypes.objectOf(PropTypes.func).isRequired,
};

People.defaultProps = {
  attendees: {},
  attendeeTypes: {},
  businessConferences: {},
  businessConferenceParticipantTypes: {},
  businessConferenceParticipants: {},
  attendeeTypeCustomFields: {},
  isFetchingAttendees: false,
  isFetchingSingleAttendee: false,
  attendeesError: undefined,
  singleAttendeeError: undefined,
  attendeesOrder: undefined,
  links: {},
  activeAttendeeId: undefined,
  ratings: {},
  denyList: {},
  recommendations: [],
};

const mapStateToProps = ({
  entities,
  entities: {
    attendeeTypeCustomFields,
    attendees,
    attendeeTypes,
    businessConferences,
    businessConferenceParticipantTypes,
    businessConferenceParticipants,
    businessConferenceRatedPossibleMeetings: ratings,
    businessConferenceParticipantBlacklists: denyList,
    appSettings,
  },
  meta: {
    recommendations,
  },
  fetchStatus: {
    attendees: {
      isFetching: isFetchingAttendees,
      error: attendeesError,
      links,
      order: attendeesOrder,
    },
    singleAttendee: {
      isFetching: isFetchingSingleAttendee,
      error: singleAttendeeError,
    },
  },
  app: {
    activeSubpage: {
      activeAttendeeId,
    },
  },
}) => ({
  attendeeTypeCustomFields,
  attendeeTypes,
  businessConferenceParticipantTypes,
  isFetchingAttendees,
  attendeesError,
  isFetchingSingleAttendee,
  singleAttendeeError,
  links,
  attendeesOrder,
  entities,
  attendees,
  businessConferences,
  businessConferenceParticipants,
  appSettings,
  activeAttendeeId,
  ratings,
  denyList,
  recommendations,
});

export default connect(mapStateToProps,
  { setActiveAttendee, callApi })(People);

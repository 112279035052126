import React, {
  useState, useEffect, useRef, useCallback, useMemo,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Grid from '@material-ui/core/Grid';
import SearchInput from 'eventtia-ui-components/lib/SearchInput';
import SelectButton from 'eventtia-ui-components/lib/SelectButton';
import Button from 'eventtia-ui-components/lib/Button';
import loadable from '@loadable/component';
import Loader from 'eventtia-ui-components/lib/Loader';
import { fetchConfig } from '../../actions/callApi';
import { setFilters } from '../../actions/filters';
import CustomPropTypes from '../../helpers/CustomPropTypes';

const FilterLoader = withStyles({
  loader: {
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})(({ classes }) => <Loader className={classes.loader} loading />);

const FetchingLoader = withStyles({
  loader: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
  },
})(({ classes }) => <Loader className={classes.loader} loading />);

// eslint-disable-next-line prefer-template
const AsyncFilterContent = loadable((props) => import('../filters/' + props.component), {
  fallback: <FilterLoader />,
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  search: {
    margin: theme.spacing(3, 0, 1.5, 0),
    display: 'flex',
    minHeight: theme.spacing(6),
    alignItems: 'center',
  },
  filters: {
    display: 'flex',
    alignItems: 'flex-end',
    color: theme.palette.darkGrey.light,
    marginBottom: theme.spacing(4),
  },
  filterSmallButton: {
    marginRight: theme.spacing(1),
  },
  filterButton: {
    height: 36,
    color: theme.palette.primary.main,
    borderRadius: 50,
  },
  submitButton: {
    display: 'inline-block',
    maxWidth: '100%',
    minWidth: 'unset',
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 2),
    borderRadius: 12,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.75),
  },
  filterContent: {
    width: 460,
    height: 300,
    maxWidth: '100%',
  },
  searchInput: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}));

export const Component = ({
  entity, activeFilters, fetchParams, fetchStatus,
  setFilters: dispatchSetFilters, sort, setSort, entities, order, meta,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['global']);
  const searchRef = useRef(null);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [currentValues, setCurrentValues] = useState({});
  const toggleFilters = useCallback(() => setFiltersOpen(!filtersOpen),
    [filtersOpen, setFiltersOpen]);

  const { filters, sortOptions } = fetchConfig[entity];
  const barFilters = useMemo(() => {
    const result = {};
    Object.keys(filters).forEach((filter) => {
      if (filters[filter].type) result[filter] = filters[filter];
    });
    return result;
  }, [filters]);

  const filtersEmpty = useMemo(() => {
    let result = true;
    Object.keys(barFilters).forEach((filter) => {
      const { isEmpty } = barFilters[filter];
      if (!isEmpty) result = false;
      else result &&= isEmpty(entities, order, meta);
    });
    return result;
  }, [barFilters, entities, order, meta]);

  const search = useCallback(() => {
    dispatchSetFilters(entity, { search: searchRef.current?.value }, fetchParams);
  }, [dispatchSetFilters, entity, fetchParams]);

  const clearSearch = useCallback(() => {
    if (activeFilters.search) dispatchSetFilters(entity, { search: undefined }, fetchParams);
    else if (searchRef.current) {
      searchRef.current.value = '';
      searchRef.current.blur();
    }
  }, [dispatchSetFilters, entity, fetchParams, activeFilters]);

  const onClearFilters = useCallback((dispatch = true) => {
    const { search: _, ...otherFilters } = activeFilters;
    if (otherFilters) {
      const cleanedFilters = {};
      Object.keys(otherFilters).forEach((currentFilter) => {
        if (typeof otherFilters[currentFilter] === 'string') cleanedFilters[currentFilter] = '';
        else if (Array.isArray(otherFilters[currentFilter])) cleanedFilters[currentFilter] = [];
        else if (typeof otherFilters[currentFilter] === 'object') {
          const subfilters = otherFilters[currentFilter];
          cleanedFilters[currentFilter] = {};
          Object.keys(subfilters).forEach((subfilter) => {
            if (typeof subfilters[subfilter] === 'string') cleanedFilters[currentFilter][subfilter] = '';
            else if (Array
              .isArray(subfilters[subfilter])) cleanedFilters[currentFilter][subfilter] = [];
          });
        }
      });
      setCurrentValues({ search: _, ...cleanedFilters });
      if (dispatch) dispatchSetFilters(entity, cleanedFilters, fetchParams);
    }
  }, [dispatchSetFilters, activeFilters, fetchParams, entity]);

  const sortItems = useMemo(() => {
    if (!sortOptions) return undefined;
    return sortOptions.map((option) => ({
      label: t(`filters.sort.${option}`),
      onClick: () => {
        setSort(option);
      },
    }));
  }, [sortOptions, setSort, t]);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = activeFilters.search || '';
      if (!activeFilters.search) searchRef.current.blur();
    }
  }, [activeFilters.search]);

  useEffect(() => {
    setCurrentValues({
      ...currentValues,
      ...activeFilters,
    });
  }, [activeFilters]);

  const onChange = useCallback((key) => (value) => {
    setCurrentValues({
      ...currentValues,
      [key]: value,
    });
  }, [setCurrentValues, currentValues]);

  const loading = fetchStatus[entity].isFetchingFirstPage;

  const onSubmit = useCallback(() => {
    dispatchSetFilters(entity, currentValues, fetchParams);
  }, [dispatchSetFilters, currentValues, entity, fetchParams]);

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        {!!sortItems && (
          <SelectButton
            label={sort ? t(`filters.sort.${sort}`) : t('filters.sort.sortBy')}
            items={sortItems}
            small
          />
        )}
        {!filtersEmpty && mobile ? (
          <IconButton
            aria-label="filter"
            className={classes.filterSmallButton}
            onClick={toggleFilters}
            color="primary"
            size="small"
          >
            <FilterListIcon />
          </IconButton>
        ) : (
          <>
            {!filtersEmpty && (
              <Button
                className={classes.filterButton}
                icon={<FilterListIcon />}
                variant="tertiary"
                onClick={toggleFilters}
                small
              >
                {filtersOpen ? t('actions.hideFilters') : t('filters.title')}
              </Button>
            )}
          </>
        )}
        {!!filters.search && (
          <SearchInput
            placeholder={t('forms.searchKeyword')}
            defaultValue={activeFilters.search || ''}
            inputRef={searchRef}
            onSubmit={search}
            onClear={clearSearch}
            className={classes.searchInput}
            fullWidth={false}
            margin="dense"
          />
        )}
      </div>
      {filtersOpen && (
        <Grid container spacing={2} className={classes.filters}>
          {barFilters && Object.keys(barFilters).map((filter) => (
            <AsyncFilterContent
              key={filter}
              value={currentValues[filter]}
              onChange={onChange(filter)}
              component={barFilters[filter].type}
            />
          ))}
          <Grid item xs={12} md={6} lg={4}>
            <Button
              variant="secondary"
              onClick={onSubmit}
              className={classes.submitButton}
              small
            >
              {t('actions.search')}
            </Button>
            <Button
              variant="tertiary"
              onClick={onClearFilters}
              className={classes.submitButton}
              small
            >
              {t('actions.clear')}
            </Button>
          </Grid>
          {loading && (
            <Grid item xs={12} md={6} lg={2}>
              <FetchingLoader />
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

Component.propTypes = {
  entity: PropTypes.string.isRequired,
  activeFilters: PropTypes.objectOf(CustomPropTypes.filter).isRequired,
  fetchParams: PropTypes.objectOf(CustomPropTypes.filter),
  setFilters: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
  entities: CustomPropTypes.entities.isRequired,
  order: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.shape({
    meetingStatuses: PropTypes.objectOf(
      PropTypes.number
    ),
  }).isRequired,
  fetchStatus: CustomPropTypes.fetchStatus.isRequired,
};

Component.defaultProps = {
  fetchParams: undefined,
  order: undefined,
};

const mapStateToProps = ({
  filters,
  entities,
  fetchStatus,
  fetchStatus: {
    attendeeTypes: {
      order,
    },
  },
  meta,
}, { entity }) => ({
  activeFilters: filters[entity],
  entities,
  fetchStatus,
  order,
  meta,
});

export default connect(mapStateToProps, { setFilters })(Component);

import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, fade } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import Block from './Block';
import DownloadActivity from '../DownloadActivity';
import useStringTransforms from '../../hooks/useStringTransforms';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import ActivityLocation from '../ActivityLocation';

const useStyles = makeStyles((theme) => ({
  secondaryLine: {
    display: 'flex',
  },
  registered: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '& *': {
      color: theme.palette.common.white,
    },
    '& $location, & $registeredText': {
      color: fade(theme.palette.common.white, 0.8),
    },
  },
  tooltipSummary: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  location: {
    fontWeight: 'normal',
    overflow: 'hidden',
  },
  locationText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    fontSize: 18,
    marginRight: theme.spacing(1),
    verticalAlign: 'bottom',
  },
  divider: {
    fontWeight: 'normal',
    margin: theme.spacing(0, 1),
  },
  registeredText: {
    display: 'flex',
    alignItems: 'center',
  },
  speakers: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 400,
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  summaryLine: {
    display: 'flex',
    alignItems: 'center',
  },
  summaryLineText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const Workshop = ({
  name, registered, id, allSpeakers, ...workshop
}) => {
  const classes = useStyles();
  const { toTitleCase } = useStringTransforms();
  const { speakers } = workshop;
  const { t } = useTranslation(['stage', 'global', 'events', 'schedule']);
  const workshopSpeakers = speakers?.filter(({ id: speakerId }) => allSpeakers[speakerId]);

  let peopleElement;
  if (workshopSpeakers.length > 1) peopleElement = workshopSpeakers.map(
    ({ id: speakerId }) => allSpeakers[speakerId]?.fullName
  ).join(', ');
  else if (workshopSpeakers.length === 1) {
    const { fullName, company, position } = allSpeakers[workshopSpeakers[0].id];
    const pipe = position || company ? ' - ' : '';
    const at = position && company ? ` ${t('global:prepositions.at')} ` : '';
    peopleElement = `${toTitleCase(fullName)}${pipe}${toTitleCase(position, true)}${at}${toTitleCase(company, true)}`;
  }

  const locationInfo = (
    <ActivityLocation activity={workshop} />
  );

  const secondaryLine = (
    <div className={classes.secondaryLine}>
      {locationInfo}
      {registered && (
        <Typography className={classes.registeredText}>
          <span className={classes.divider}>|</span>
          <HowToRegIcon color="primary" className={classes.icon} />
          {t('global:status.registered')}
        </Typography>
      )}
    </div>
  );

  const tertiaryLine = peopleElement ? (
    <Typography className={classes.speakers}>{peopleElement}</Typography>
  ) : undefined;

  const summaryLine = (
    <div className={classes.summaryLine}>
      {registered && (
        <HowToRegIcon color="inherit" className={classes.icon} />
      )}
      <span className={classes.summaryLineText}>
        {name}
        <span className={classes.divider}>|</span>
        {locationInfo}
      </span>
    </div>
  );

  const tooltipContent = (
    <>
      {peopleElement && (
        <Typography className={classes.speakers}>{peopleElement}</Typography>
      )}
      {locationInfo}
    </>
  );

  const { eventUri } = useParams();
  return (
    <Block
      {...workshop}
      className={registered ? classes.registered : undefined}
      downloadActivity={eventUri === 'ic2021' ? undefined : (
        <DownloadActivity
          id={id}
          name={name}
        />
      )}
      tooltipTitle={tooltipContent}
      mainLine={name}
      secondaryLine={secondaryLine}
      tertiaryLine={tertiaryLine}
      summaryLine={summaryLine}
    />
  );
};

Workshop.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  registered: PropTypes.bool,
  allSpeakers: PropTypes.objectOf(
    CustomPropTypes.speaker
  ),
};

Workshop.defaultProps = {
  registered: false,
  allSpeakers: {},
};

const mapStateToProps = ({
  entities: {
    speakers: allSpeakers,
  },
}) => ({
  allSpeakers,
});

export default connect(mapStateToProps, null)(Workshop);

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { connect, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import Button from 'eventtia-ui-components/lib/Button';
import Tooltip from 'eventtia-ui-components/lib/Tooltip';
import Modal from 'eventtia-ui-components/lib/Modal';
import VideocamIcon from '@material-ui/icons/Videocam';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Message from 'eventtia-ui-components/lib/Message';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SimpleBar from 'simplebar-react';
import useDidUpdate from '../../hooks/useDidUpdate';
import callApi from '../../actions/callApi';
import { setActiveWorkshopSpeaker } from '../../actions/app';
import useRoutes from '../../hooks/useRoutes';
import { baseMoment, getEventTimeFormat, showInEventTimezone } from '../../helpers/dates';
import { getModuleByType, getCurrentAttendee } from '../../helpers/getters';
import { getPriceByAttendeeId } from '../../helpers/workshops';
import attendanceModeHelper from '../../helpers/attendanceModeHelper';
import useStringTransforms from '../../hooks/useStringTransforms';
import Biography from '../Biography';
import { getUpdateRegistrationUrl } from '../../helpers/endpoints';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import AttachmentFile from '../../components/AttachmentFile';
import DownloadActivity from '../../components/DownloadActivity';
import useTraces, { ENTITY_TYPES } from '../../hooks/useTraces';
import { ATTENDANCE_MODE } from '../../helpers/constants';
import intlLocales from '../../helpers/intlLocales';
import usePolling from '../../hooks/usePolling';
import { selectLogo } from '../../helpers/selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    '& ::-webkit-scrollbar': {
      width: 10,
      height: 10,
    },
    /* Handle */
    '& ::-webkit-scrollbar-track': {
      WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      borderRadius: 10,
    },
    '& ::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: theme.spacing(1),
    },
    /* Handle on hover */
    '& ::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.dark,
    },
  },
  subtitle: {
    fontWeight: 'bold',
    margin: theme.spacing(5, 0, 2, 0),
    color: theme.palette.darkGrey.main,
  },
  description: {
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  invitation: {
    margin: theme.spacing(2, 0, 1),
    '& i': {
      color: theme.palette.primary.light,
    },
  },
  advice: {
    margin: theme.spacing(2, 0, 1),
    '& button': {
      display: 'none',
    },
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'center',
  },
  modalButton: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    margin: theme.spacing(0, -1),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  button: {
    maxWidth: 'calc(50% - 16px)',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      maxWidth: '100%',
    },
    width: '50%',
    margin: theme.spacing(2, 1, 0),
  },
  avatars: {
    textAlign: 'center',
  },
  avatarsSlider: {
    overflowY: 'hidden',
    width: '100%',
  },
  sliderContent: {
    whiteSpace: 'nowrap',
  },
  avatar: {
    margin: theme.spacing(2, 0),
    display: 'inline-flex',
    height: 90,
    width: 90,
    boxShadow: theme.customShadows.small,
  },
  avatarInSlider: {
    marginLeft: theme.spacing(-5),
    '&:first-of-type': {
      marginLeft: theme.spacing(2.5),
    },
    '&:last-of-type': {
      marginRight: theme.spacing(2.5),
    },
  },
  logoAvatar: {
    '& img': {
      objectFit: 'contain',
    },
  },
  imageAvatar: {
    borderRadius: '50%',
    height: 90,
    width: 90,
    objectFit: 'cover',
    fontSize: 70,
    '&:hover': {
      height: 100,
      width: 100,
      backgroundColor: theme.palette.lightGrey.main,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.light,
      objectFit: 'cover',
      zIndex: 1,
    },
  },
  noAvatar: {
    fontSize: '2em',
  },
  timespan: {
    color: theme.palette.primary.light,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    '& > svg': {
      fontSize: '1.5em',
      marginRight: theme.spacing(1),
    },
  },
  name: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: 'bold',
    margin: theme.spacing(1, 0),
    textAlign: 'center',
  },
  location: {
    fontSize: theme.typography.subtitle2.fontSize,
    margin: theme.spacing(1, 0),
    textAlign: 'center',
  },
  job: {
    fontSize: theme.typography.subtitle2.fontSize,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  filesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  modalPaper: {
    borderRadius: 10,
  },
  dialog: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.darkGrey.main,
    padding: theme.spacing(2),
    cursor: 'auto',
    boxShadow: theme.customShadows.default,
  },
  modalContent: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  modalText: {
    fontWeight: 'bold',
  },
  modalHelperText: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
  attendanceModeMessage: {
    boxShadow: '0px 3px 6px #00000029',
  },
  image: {
    height: 'auto',
    width: '100%',
  },
  price: {
  },
}));

const { VIRTUAL, PHYSICAL } = ATTENDANCE_MODE;

const Workshop = ({
  attendeeUnregisterError,
  attendeeRegisterSuccess, attendeeRegisterError,
  workshop,
  speakers,
  setActiveWorkshopSpeaker: dispatchSetActiveWorkshopSpeaker,
  callApi: dispatchCallApi,
  eventFiles,
  speakerId,
  events,
  appSettings,
  attendeeWorkshops,
  entities,
  currentAttendanceMode,
  fetchingRegistrationToken,
  fetchingCurrentAttendee,
}) => {
  const classes = useStyles();
  const { toTitleCase } = useStringTransforms();

  const [settings] = Object.values(appSettings);
  const {
    customParams: { itemsToShow, allowRegistration },
  } = getModuleByType(settings.modules, 'Workshops');
  const highlightRegistered = itemsToShow === 'attendee_type_and_highlight_registered';
  const offsetToUtc = !showInEventTimezone(settings);

  const {
    id: wId, name, description, streamingType, streamingUrl, location,
    startDate, endDate, zoomType, fileCategoryId, showOnRegister, price,
    sponsorId, availability, usedSeats, attendanceMode: workshopAttendanceMode,
  } = workshop;

  const currentAttendee = getCurrentAttendee(entities);
  const attendeeUuid = currentAttendee?.uuid;

  const logo = useSelector(selectLogo());

  const { goTo } = useRoutes();
  const { eventUri, locale } = useParams();
  const dateFormat = locale === 'fr' ? 'Do MMM' : 'MMM Do';
  const timeFormat = getEventTimeFormat(events);
  const [{ endDate: eventEndDate, currency }] = Object.values(events);
  const { t } = useTranslation(['schedule', 'stage', 'global']);

  const workshopEnded = baseMoment().isAfter(endDate);
  const workshopIsInTheFuture = baseMoment().isBefore(startDate);
  const live = baseMoment().isBetween(startDate, endDate, undefined, '[)'); // TODO tick?

  const currentPrice = getPriceByAttendeeId(currentAttendee.attendeeType.id, price);
  const freeWorkshop = currentPrice === 0;
  const formattedPrice = `${currency} ${new Intl.NumberFormat(intlLocales[locale]).format(currentPrice)}`;

  const attendeeIsRegistered = Object.values(attendeeWorkshops || {}).find(
    ({ attendee: { id: aId }, workshop: { id: workshopId } }) => (
      aId === currentAttendee.id && workshopId === wId
    )
  );
  const attendeeIsRegisteredAndPaid = (
    attendeeIsRegistered && (freeWorkshop || currentAttendee.paid)
  );

  const activeSpeaker = speakers[speakerId];
  const [event] = Object.values(events);
  const eventMap = event?.staticMap;
  const { canRegisterMidWorkshop } = event || {};

  const currentAttendeeTypeId = currentAttendee.attendeeType.id;
  const { attendeeTypes } = entities;
  const attendeeTypeAttendanceMode = attendeeTypes[currentAttendeeTypeId].attendanceMode;

  const urlFixed = (url) => { // TODO use the one defined in helper and rename
    if (!/^https?:\/\//i.test(url)) return `http://${url}`;
    return url;
  };

  const goToSpeaker = (id) => {
    dispatchSetActiveWorkshopSpeaker(wId, id);
  };

  const workshopSpeakers = workshop.speakers?.map(({ id: wSpeakerId }) => {
    const {
      bio, id, fullName, picture, company, position,
    } = speakers[wSpeakerId];
    return {
      bio,
      id,
      fullName,
      company,
      position,
      picture,
    };
  });

  const [modalType, setModalType] = useState(false);
  const closeModal = () => setModalType();

  const register = () => dispatchCallApi('registerAttendee', { eventUri, workshopId: wId });
  const unregister = () => {
    dispatchCallApi('unregisterAttendee', { eventUri, workshopId: wId });
    closeModal();
  };

  const goToRegistrationUpdate = () => {
    dispatchCallApi('registrationToken', { eventUri }).then(({
      response: {
        register_token: registerToken,
      },
    }) => {
      const attendeeRegistrationUpdateLink = getUpdateRegistrationUrl(
        eventUri, attendeeUuid, registerToken
      );
      window.open(attendeeRegistrationUpdateLink, '_blank').focus();
    });
    closeModal();
  };
  const registerToPaid = async () => {
    if (!attendeeIsRegistered) await register();
    dispatchCallApi('refetchCurrentAttendee', { eventUri, id: currentAttendee.id, offsetToUtc });

    goToRegistrationUpdate();
  };

  const modalConfig = {
    unregister: {
      text: t('stage:activity.unregisterConfirm', { activity: name }),
      onAccept: unregister,
      buttonText: t('stage:actions.unregister'),
    },
    registerToPaid: {
      text: t('stage:activity.registerToPaidConfirm'),
      helperText: (
        <Typography className={classes.modalHelperText}>
          <b>{t('stage:activity.itCosts', { price: formattedPrice })}</b> {t('stage:activity.registerToPaidHelperText')}
        </Typography>
      ),
      onAccept: registerToPaid,
      buttonText: t('stage:actions.register'),
    },
  };

  const [message, setMessage] = useState({
    title: undefined,
    message: undefined,
    type: undefined,
  });

  const [showAdvice, setShowAdvice] = useState(true);

  useEffect(() => {
    if (wId && fileCategoryId) dispatchCallApi('workshopsFiles', { eventUri, fileCategoryId, id: wId });
  }, [eventUri, wId, fileCategoryId, dispatchCallApi]);
  const workshopFiles = Object.values(eventFiles).filter(
    (file) => file.entityType === 'Workshop' && file.entityId.toString() === wId
  );

  useDidUpdate(() => {
    const errorMsg = attendeeRegisterError || attendeeUnregisterError;
    if (attendeeRegisterError) setMessage({ title: t('registrationMessages.errorRegisteredOnWorkshopTitle'), message: errorMsg, type: 'warning' });
    if (attendeeUnregisterError) setMessage({ title: t('registrationMessages.errorUnregisterOnWorkshopTitle'), message: errorMsg, type: 'warning' });
  }, [attendeeUnregisterError, attendeeRegisterError, setMessage, t]);

  useDidUpdate(() => {
    if (attendeeRegisterSuccess) setMessage({ title: t('registrationMessages.registeredOnWorkshopTitle'), message: t('registrationMessages.registeredOnWorkshopDescription'), type: 'success' });
  }, [attendeeRegisterSuccess, setMessage, t]);

  let speakersElement;
  let avatarElement;
  if (workshopSpeakers.length > 1) {
    speakersElement = workshopSpeakers.map(({ fullName }) => fullName).join(', ');
    avatarElement = (
      <SimpleBar className={classes.avatarsSlider}>
        <div className={classes.sliderContent}>
          {workshopSpeakers.map(({ fullName, picture, id }) => (
            <IconButton
              key={id}
              className={clsx(classes.avatar, classes.avatarInSlider)}
              onClick={() => goToSpeaker(id)}
            >
              <Tooltip title={fullName} placement="top">
                <Avatar alt={fullName} src={picture.small} className={classes.imageAvatar} />
              </Tooltip>
            </IconButton>
          ))}
        </div>
      </SimpleBar>
    );
  } else if (workshopSpeakers.length === 1) {
    const [{
      fullName, picture, company, position, id,
    }] = workshopSpeakers;
    const pipe = position || company ? ' | ' : '';
    const at = position && company ? ` ${t('global:prepositions.at')} ` : '';
    speakersElement = `${toTitleCase(fullName)}${pipe}${toTitleCase(position, true)}${at}${toTitleCase(company, true)}`;
    avatarElement = (
      <IconButton key={id} className={classes.avatar} onClick={() => goToSpeaker(id)}>
        <Tooltip title={fullName} placement="top">
          <Avatar alt={fullName} src={picture.small} className={classes.imageAvatar} />
        </Tooltip>
      </IconButton>
    );
  } else avatarElement = (
    <Avatar className={clsx(classes.avatar, classes.logoAvatar)} src={logo} alt="">
      <FreeBreakfastIcon className={classes.noAvatar} />
    </Avatar>
  );

  const { trackOnce } = useTraces(wId, ENTITY_TYPES.WORKSHOP, false);

  const joinStream = () => {
    const ongoingWorkshop = baseMoment()
      .isBetween(startDate, eventEndDate, undefined, '[)');
    if (sponsorId && ongoingWorkshop) {
      const push = trackOnce(0);
      if (push) push.receive('ok').receive('error', ({ reason }) => {
        // eslint-disable-next-line no-console
        console.error('failed to join channel', reason);
      });
    }
    const shouldOpenTab = !['youtube', 'other', 'jitsi', 'jitsi_oficial', 'daily_co', 'eventtia_studio']
      .includes(streamingType) && zoomType !== 'embedded';
    if (!(sponsorId && shouldOpenTab)) goTo('stage.show', { workshopId: wId }); // TODO why sponsorId
    if (shouldOpenTab) window.open(urlFixed(streamingUrl), '_blank', 'noopener noreferrer');
  };

  const canJoinWorkshopStream = attendanceModeHelper.canJoinWorkshopStream(
    workshopAttendanceMode, currentAttendanceMode, attendeeTypeAttendanceMode
  );

  const canRegisterByAttendanceMode = attendanceModeHelper.canRegisterByAttendanceMode(
    workshopAttendanceMode, attendeeTypeAttendanceMode
  );

  const wrongAttendanceMode = attendanceModeHelper.showWrongAttendanceModeMessage(
    workshopAttendanceMode, currentAttendanceMode, attendeeTypeAttendanceMode
  );

  const showJoinButton = !fetchingCurrentAttendee && (
    sponsorId
      ? (!!streamingUrl && (attendeeIsRegisteredAndPaid || !showOnRegister))
      // TODO verify these
      : (!!streamingType && canJoinWorkshopStream && (
        !highlightRegistered || attendeeIsRegisteredAndPaid || !showOnRegister
      ))
  );

  const showFreeRegisterButton = freeWorkshop
  && canRegisterByAttendanceMode
  && (workshopIsInTheFuture || (canRegisterMidWorkshop && !workshopEnded))
  && (
    sponsorId ? (
      showOnRegister && !attendeeIsRegistered
    ) : (
      highlightRegistered && !attendeeIsRegistered && allowRegistration && showOnRegister
    )
  ); // TODO and these

  const showRegisterToPaidWorkshopButton = (
    workshopIsInTheFuture
    || (canRegisterMidWorkshop && !workshopEnded)
  )
  && allowRegistration
  && !freeWorkshop
  && canRegisterByAttendanceMode
  && (!attendeeIsRegistered || !currentAttendee?.paid);

  const [physicalOnlyMessage, setPhysicalOnlyMessage] = useState({});

  useEffect(() => {
    if (workshopAttendanceMode === PHYSICAL && wrongAttendanceMode) setPhysicalOnlyMessage(
      { title: t('schedule:title.physicallyOnlyActivity'), message: t('schedule:info.physicallyOnlyActivity'), type: 'warning' }
    );
  }, [currentAttendanceMode]);

  const [virtualOnlyMessage, setVirtualOnlyMessage] = useState({});

  useEffect(() => {
    if (workshopAttendanceMode === VIRTUAL && wrongAttendanceMode) setVirtualOnlyMessage(
      { title: t('schedule:title.virtuallyOnlyActivity'), message: t('schedule:info.virtuallyOnlyActivity'), type: 'warning' }
    );
  }, [currentAttendanceMode]);

  usePolling({
    endpoint: 'refetchCurrentAttendee',
    params: { eventUri, id: currentAttendee.id, offsetToUtc },
    duration: 15000,
    active: (attendeeIsRegistered && !currentAttendee?.paid),
  });

  const purchaseButton = attendeeIsRegistered ? {
    label: t('stage:activity.payPendingCharges'),
    onClick: goToRegistrationUpdate,
  } : {
    label: t('stage:actions.purchase'),
    onClick: () => setModalType('registerToPaid'),
  };

  return (
    <div className={classes.root}>
      {activeSpeaker
        ? (
          <Biography
            speaker={activeSpeaker}
          />
        )
        : (
          <>
            <Message
              onClose={() => setPhysicalOnlyMessage(undefined)}
              type={physicalOnlyMessage?.type}
              title={physicalOnlyMessage?.title}
              message={physicalOnlyMessage?.message || undefined}
              className={classes.attendanceModeMessage}
            />
            <Message
              onClose={() => setVirtualOnlyMessage(undefined)}
              type={virtualOnlyMessage?.type}
              title={virtualOnlyMessage?.title}
              message={virtualOnlyMessage?.message || undefined}
              className={classes.attendanceModeMessage}
            />
            <div
              className={classes.avatars}
            >
              {avatarElement}
            </div>
            <Typography className={classes.timespan}>
              <AccessTimeIcon />
              <span>{baseMoment(startDate).format(`${dateFormat}, ${timeFormat}`)} - {baseMoment(endDate).format(timeFormat)}</span>
            </Typography>
            <Typography className={classes.name} component="div">
              {name}
            </Typography>
            {location && (
              <Typography className={classes.location} component="div">
                {location}
              </Typography>
            )}
            {speakersElement && (
              <Typography className={classes.job}>
                {speakersElement}
              </Typography>
            )}
            <Divider />
            {!!currentPrice && (
              <>
                <Typography className={classes.subtitle}>{t('title.price')}</Typography>
                <Typography variant="body2" className={classes.price}>
                  {formattedPrice}
                </Typography>
              </>
            )}
            {!!description && (
              <>
                <Typography className={classes.subtitle}>{t('title.activityDescription')}</Typography>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </>
            )}
            {/* no lo quito del todo porque me huele a que va a tocar volver a poner esto */}
            {false && eventMap && attendanceModeHelper.getMap(workshopAttendanceMode) && (
              <>
                <Typography className={classes.subtitle} variant="subtitle2">
                  {t('title.location')}
                </Typography>
                <img className={classes.image} src={eventMap} alt={t('info.location')} />
              </>
            )}
            {!!workshopFiles.length && (
              <>
                <Typography className={classes.subtitle} variant="subtitle2">
                  {t('title.attachments')}
                </Typography>
                <div className={classes.filesContainer}>
                  {workshopFiles.map((file) => (
                    <AttachmentFile key={file.id} file={file} />
                  ))}
                </div>
              </>
            )}
            {live && showJoinButton && (
              <Typography className={classes.invitation}>
                <Trans t={t} i18nKey="stage:activity.invitation">
                  We’re live on {{ streamingType }}, click on <i>join</i> to get started
                </Trans>
              </Typography>
            )}
            <Message
              onClose={() => setMessage({})}
              type={message?.type}
              title={message?.title}
              message={message?.message}
              className={classes.invitation}
            />
            {showFreeRegisterButton && showAdvice && (
              <Message
                className={classes.advice}
                onClose={() => setShowAdvice(false)}
                message={t('stage:activity.freeWorkshopAdvice')}
                type="warning"
              />
            )}
            {showRegisterToPaidWorkshopButton && showAdvice && (
              <Message
                className={classes.advice}
                onClose={() => setShowAdvice(false)}
                message={t('stage:activity.paymentAdvice', { button: purchaseButton.label })}
                type="warning"
              />
            )}
            <div className={classes.buttons}>
              {eventUri !== 'ic2021' && !workshopEnded && (
                <DownloadActivity
                  id={wId}
                  name={name}
                  className={classes.button}
                  button
                />
              )}
              {showJoinButton && (
                <Button
                  variant="primary"
                  className={classes.button}
                  onClick={joinStream}
                  icon={<VideocamIcon />}
                >
                  {t('stage:actions.join')}
                </Button>
              )}
              {showFreeRegisterButton && (
                <Button
                  variant="primary"
                  className={classes.button}
                  onClick={register}
                  disabled={
                    !!availability && (usedSeats >= availability)
                  }
                >
                  {t('stage:actions.register')}
                </Button>
              )}
              {showRegisterToPaidWorkshopButton && (
                <Button
                  variant="primary"
                  className={classes.button}
                  onClick={purchaseButton.onClick}
                  disabled={
                    fetchingRegistrationToken
                    || (!!availability && (usedSeats >= availability))
                  }
                >
                  {purchaseButton.label}
                </Button>
              )}
            </div>
            {!!modalType && (
              <Modal
                open
                handleClose={closeModal}
                classes={{ paper: classes.modalPaper }}
              >
                <div className={classes.dialog}>
                  <div className={classes.modalContent}>
                    <Typography className={classes.modalText}>
                      {modalConfig[modalType].text}
                    </Typography>
                    {modalConfig[modalType].helperText}
                  </div>
                  <div className={classes.modalButtons}>
                    <Button
                      small
                      variant="tertiary"
                      onClick={closeModal}
                      className={classes.modalButton}
                    >
                      {t('global:actions.cancel')}
                    </Button>
                    <Button
                      small
                      variant="primary"
                      onClick={modalConfig[modalType].onAccept}
                      className={classes.modalButton}
                    >
                      {modalConfig[modalType].buttonText}
                    </Button>
                  </div>
                </div>
              </Modal>
            )}
            {highlightRegistered && attendeeIsRegisteredAndPaid
              && allowRegistration && showOnRegister && freeWorkshop && (
                <>
                  <Typography className={classes.invitation}>
                    {t('stage:activity.notInterested')}
                  </Typography>
                  <div className={classes.buttons}>
                    <Button
                      variant="secondary"
                      className={classes.button}
                      onClick={() => setModalType('unregister')}
                      icon={<RemoveCircleOutlineIcon />}
                    >
                      {t('stage:actions.unregister')}
                    </Button>
                  </div>
                </>
            )}
          </>
        )}
    </div>
  );
};

Workshop.propTypes = {
  attendeeUnregisterError: PropTypes.string,
  attendeeRegisterSuccess: PropTypes.bool,
  attendeeRegisterError: PropTypes.string,
  attendeeWorkshops: PropTypes.objectOf(
    CustomPropTypes.attendeeWorkshops
  ),
  appSettings: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
  speakerId: PropTypes.string,
  workshop: CustomPropTypes.workshop.isRequired,
  setActiveWorkshopSpeaker: PropTypes.func.isRequired,
  speakers: PropTypes.objectOf(
    CustomPropTypes.speaker
  ),
  entities: CustomPropTypes.entities.isRequired,
  callApi: PropTypes.func.isRequired,
  eventFiles: PropTypes.objectOf(
    CustomPropTypes.eventFile
  ),
  currentAttendanceMode: PropTypes.oneOf([PHYSICAL, VIRTUAL]),
  fetchingRegistrationToken: PropTypes.bool,
  fetchingCurrentAttendee: PropTypes.bool.isRequired,
};

Workshop.defaultProps = {
  speakerId: undefined,
  speakers: {},
  eventFiles: {},
  attendeeWorkshops: {},
  attendeeRegisterSuccess: false,
  attendeeUnregisterError: undefined,
  attendeeRegisterError: undefined,
  currentAttendanceMode: '',
  fetchingRegistrationToken: false,
};

const mapStateToProps = ({
  fetchStatus: {
    registerAttendee: {
      success: attendeeRegisterSuccess,
      error: attendeeRegisterError,
    },
    unregisterAttendee: {
      error: attendeeUnregisterError,
    },
    registrationToken: {
      isFetching: fetchingRegistrationToken,
    },
    refetchCurrentAttendee: {
      isFetching: fetchingCurrentAttendee,
    },
  },
  app: {
    activeSubpage: {
      speakerId,
    },
    attendanceType: currentAttendanceMode,
  },
  entities,
  entities: {
    attendeeWorkshops,
    appSettings,
    speakers,
    eventFiles,
    events,
  },
}) => ({
  entities,
  currentAttendanceMode,
  attendeeWorkshops,
  appSettings,
  events,
  speakerId,
  speakers,
  eventFiles,
  attendeeUnregisterError,
  attendeeRegisterSuccess,
  attendeeRegisterError,
  fetchingRegistrationToken,
  fetchingCurrentAttendee,
});

export default connect(
  mapStateToProps,
  { callApi, setActiveWorkshopSpeaker }
)(Workshop);

/* eslint camelcase: 2 */
/* eslint dot-notation: [2, {"allowPattern": "^[a-z]+(_[a-z]+)+$"}] */
import React, {
  useEffect, useRef, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import OldReactGA from 'react-ga';
import ReactGA from 'react-ga4';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  useLocation, Switch, Route, Redirect, useParams, useHistory,
} from 'react-router-dom';
import { stringify } from 'qs';
import moment from 'moment-timezone';
import i18next from 'i18next';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/pt'; // PT-VS-HACK
import 'moment/locale/it'; // IT-VS-HACK
// import 'moment/locale/ja'; // JA-VS-HACK
// import 'moment/locale/zh-cn'; // ZH-VS-HACK
import 'moment/locale/ca';
import 'moment/locale/ar'; // AR-VS-HACK
import 'moment/locale/tr'; // TR-VS-HACK
import 'moment/locale/de'; // DE-VS-HACK
import callApi from '../actions/callApi';
import { fetchAttendeeLeads } from '../actions/attendeeLeads';
import { logout, setActiveSubpage } from '../actions/app';
import {
  loggedIn, getTokenContent, enableForceMeeting, enableForceWorkshop, enableUsingHLS,
} from '../helpers/auth';
import useQuery from '../hooks/useQuery';
import withInitialData from '../hocs/withInitialData';
import AsyncPage from '../hocs/AsyncPage';
import useRoutes from '../hooks/useRoutes';
import LoggedOutLayout from '../components/LoggedOutLayout';
import LoggedInLayout from '../components/LoggedInLayout';
import createTheme from '../helpers/createTheme';
import useTimer from '../hooks/useTimer';
import usePrevious from '../hooks/usePrevious';
import {
  getEntity, getCurrentParticipant, getCurrentAttendee, getModuleByType,
} from '../helpers/getters';
import CustomPropTypes from '../helpers/CustomPropTypes';
import { showInEventTimezone } from '../helpers/dates';
import setCustomManifest from '../helpers/setCustomManifest';
import useEventUriHack from '../hooks/hacks/useEventUriHack';

const EVENTTIA_GA4_ID = 'G-PY40TMSGQP';

// HACK
const hackedFonts = {
  '50b1': {
    url: 'https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap',
    fontFamily: 'Tenor Sans',
  },
  netwirkig: {
    url: 'https://fonts.googleapis.com/css2?family=Honk&family=Tenor+Sans&display=swap',
    fontFamily: 'Honk',
  },
};

export const Component = ({
  currentAttendeeFetched, loggedInSuccessfully, currentEvent,
  callApi: dispatchCallApi, settings, entities, logout: dispatchLogout,
  setActiveSubpage: dispatchSetActiveSubpage,
  fetchAttendeeLeads: dispatchFetchAttendeeLeads,
}) => {
  const { loggedInRoutes, urlTo, pathTo } = useRoutes();
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    forceMeeting, forceWorkshop, usingHLS, ...otherQueryParams
  } = useQuery();
  const { locale } = useParams();
  const {
    eventUri,
    account: { id: accountId },
    googleAnalyticsTrackingCode,
    googleAnalytics4TrackingCode,
  } = currentEvent;
  const { enabled } = settings;
  const offsetToUtc = !showInEventTimezone(settings);
  const skipAnalyticsTrackings = useEventUriHack('skipAnalyticsTrackings');

  useEffect(() => {
    moment.locale(locale);
    i18next.changeLanguage(locale);
  }, [locale]);

  useEffect(() => {
    setCustomManifest(currentEvent);
  }, []);

  useEffect(() => {
    const id = getTokenContent(eventUri)?.['attendee_id'];
    if (id && enabled) dispatchCallApi('currentAttendee', { eventUri, id, offsetToUtc });
    else if (id) dispatchLogout();
  }, [dispatchCallApi, loggedInSuccessfully, eventUri, enabled, offsetToUtc]);

  useEffect(() => {
    if (currentAttendeeFetched) dispatchCallApi('speakers', { eventUri });
  }, [dispatchCallApi, eventUri, currentAttendeeFetched]);

  const { customParams: { itemsToShow } } = getModuleByType(settings.modules, 'Workshops');
  const shouldFetchLeads = getModuleByType(settings.modules, 'AttendeeLeads')?.enabled;
  const shouldFetchQASessions = getModuleByType(settings.modules, 'QASessions')?.enabled;

  const shouldFilterByType = [
    'only_attendee',
    'attendee_type_and_highlight_registered',
    'only_attendee_type',
    'inactive_register_attendee_type_registered',
  ].includes(itemsToShow);

  const currentAttendee = getCurrentAttendee(entities);
  const attendeeTypeId = currentAttendee?.attendeeType?.id;
  const currentAttendeeType = entities?.attendeeTypes?.[attendeeTypeId];
  const participantTypeId = currentAttendeeType?.businessConferenceParticipantType?.id;
  const currentParticipantType = entities?.businessConferenceParticipantTypes?.[participantTypeId];
  const { scheduleRestrictions } = currentParticipantType || {};

  const shouldFetchAttendeeWorkshops = [
    'only_attendee',
    'attendee_type_and_highlight_registered',
    'inactive_register_attendee_type_registered',
  ].includes(itemsToShow) || !!scheduleRestrictions;

  useEffect(() => {
    if (!currentAttendeeFetched) return;
    const workshopsParams = { eventUri };
    if (shouldFilterByType) workshopsParams.attendeeTypeId = attendeeTypeId;
    dispatchCallApi('workshops', workshopsParams);
    if (shouldFetchAttendeeWorkshops) dispatchCallApi('attendeeWorkshops', { eventUri });
  }, [
    dispatchCallApi, currentAttendeeFetched, eventUri, attendeeTypeId, shouldFetchAttendeeWorkshops,
    shouldFilterByType,
  ]);

  const businessConference = getEntity(entities, 'businessConferences');
  const businessConferenceId = businessConference && businessConference.id;
  const multipleRequestsEnabled = businessConference && businessConference.multipleRequestsEnabled;
  const participantId = getCurrentParticipant(entities)?.id;
  useEffect(() => {
    if (currentAttendeeFetched && businessConferenceId && participantId) dispatchCallApi('meetings', {
      eventUri, businessConferenceId, participantId, offsetToUtc,
    });
  }, [
    dispatchCallApi, currentAttendeeFetched, eventUri, businessConferenceId, participantId,
    offsetToUtc,
  ]);

  useEffect(() => {
    if (businessConferenceId) dispatchCallApi('participantTypes', {
      eventUri, businessConferenceId, offsetToUtc,
    });
  }, [dispatchCallApi, eventUri, businessConferenceId, offsetToUtc]);

  useEffect(() => {
    if (multipleRequestsEnabled) dispatchCallApi('meetingsRequests', {
      eventUri, businessConferenceId,
    });
  }, [dispatchCallApi, eventUri, businessConferenceId, multipleRequestsEnabled]);

  useEffect(() => {
    if (shouldFetchLeads && currentAttendeeFetched) dispatchFetchAttendeeLeads(eventUri);
  }, [currentAttendeeFetched, eventUri, shouldFetchLeads]);

  useEffect(() => {
    if (shouldFetchQASessions && currentAttendeeFetched) dispatchCallApi('qaSessions', {
      eventUri,
    });
  }, [dispatchCallApi, eventUri, shouldFetchQASessions, currentAttendeeFetched]);

  useEffect(() => {
    // HACK skip analytics tracking for these events
    if (!skipAnalyticsTrackings) {
      const products = [{
        trackingId: EVENTTIA_GA4_ID,
        gaOptions: { name: 'eventtia' },
      }]; // products used to be called trackers
      if (googleAnalytics4TrackingCode) products.push({
        trackingId: googleAnalytics4TrackingCode,
        gaOptions: { name: 'customer-ga4' },
      });
      ReactGA.initialize(products);

      if (googleAnalyticsTrackingCode) {
        const trackers = [{
          trackingId: googleAnalyticsTrackingCode,
          gaOptions: { name: 'customer' },
        }];
        OldReactGA.initialize(trackers);
      }
    }
  }, [
    googleAnalyticsTrackingCode,
    googleAnalytics4TrackingCode,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    OldReactGA.pageview(pathname, ['customer']);
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);
  const heartbeat = useCallback(() => {
    OldReactGA.event({
      category: 'User',
      action: 'Heartbeat',
      nonInteraction: true,
    }, ['customer']);
    ReactGA.event({
      category: 'User',
      action: 'Heartbeat',
      nonInteraction: true,
    });
  }, []);
  useTimer({ onTick: heartbeat, step: 10 * 60 * 1000, clearOnEnd: false });

  useEffect(() => {
    if (forceMeeting) enableForceMeeting();
  }, [forceMeeting]);
  useEffect(() => {
    if (forceWorkshop) enableForceWorkshop();
  }, [forceWorkshop]);
  useEffect(() => {
    if (usingHLS) enableUsingHLS();
  }, [usingHLS]);

  const { name } = currentEvent;
  const {
    primaryColor, secondaryColor, navbarColor, icon, loginWelcomeMessage,
  } = settings;

  useEffect(() => {
    document.querySelector('title').innerHTML = name;
    document.querySelector('link[rel=apple-touch-icon]').setAttribute('href', icon.filename ? icon.thumb : '/default-logo.png');
    document.querySelector('link[rel=\'shortcut icon\']').setAttribute('href', icon.filename ? icon.thumb : '/favicon.ico');
    document.querySelector('meta[name=theme-color]').setAttribute('content', primaryColor);

    if (hackedFonts[eventUri]) {
      const fontTag = document.createElement('link');
      fontTag.rel = 'stylesheet';
      fontTag.href = hackedFonts[eventUri].url;
      document.getElementsByTagName('head')[0].appendChild(fontTag);
    }
  }, [name, icon, primaryColor, eventUri]);

  const theme = useMemo(() => createTheme({
    primaryColor, secondaryColor, navbarColor, fontFamily: hackedFonts[eventUri]?.fontFamily,
  }), [primaryColor, secondaryColor, navbarColor, eventUri]);

  const {
    module, email: autoLoginEmail, token: autoLoginToken, ssoToken, ...moduleParams
  } = otherQueryParams;
  const { enabled: homeEnabled } = getModuleByType(settings.modules, 'Home') || {};
  const defaultInitialUrl = urlTo(homeEnabled ? 'home' : 'stage');
  const initialURL = useRef(defaultInitialUrl);

  useEffect(() => {
    const initialQuery = module ? `?${stringify({ module, ...moduleParams }, { encode: false })}` : '';
    const ENDS_WITH_LOGIN = RegExp(`${eventUri}/login/?$`);
    const ROOT_PATHNAME = RegExp(`/${locale}/${eventUri}/?$`);

    let initialPathname;
    if (ENDS_WITH_LOGIN.test(pathname)) initialPathname = pathname.replace(urlTo('login'), defaultInitialUrl);
    else initialPathname = ROOT_PATHNAME.test(pathname) ? defaultInitialUrl : pathname;

    initialURL.current = `${initialPathname}${initialQuery}`;
  }, []);

  const keepAutologinParams = !loggedIn(eventUri) && !currentAttendeeFetched;
  const initialQueryParams = {
    ...moduleParams,
  };
  if (keepAutologinParams) {
    initialQueryParams.email = autoLoginEmail;
    initialQueryParams.token = autoLoginToken;
    initialQueryParams.ssoToken = ssoToken;
  }

  useEffect(() => {
    const path = window.location.pathname.split('/').filter((el) => el.length !== 0);
    const isInMainStage = path.includes('stage') || path.includes('meeting');

    if (homeEnabled && !isInMainStage) dispatchSetActiveSubpage({ module: 'Home', ...initialQueryParams });
    if (module) dispatchSetActiveSubpage({ module, ...initialQueryParams });
  }, []);

  const prevAttendeeTypeId = usePrevious(attendeeTypeId);
  const justLoggedIn = !!attendeeTypeId && !prevAttendeeTypeId;
  const justLoggedOut = !attendeeTypeId && !!prevAttendeeTypeId;

  useEffect(() => {
    if (justLoggedOut) initialURL.current = defaultInitialUrl;
    if (justLoggedIn) history.push(initialURL.current);
  }, [justLoggedIn, justLoggedOut, locale, eventUri]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {(loggedIn(eventUri) || currentAttendeeFetched) ? (
        <LoggedInLayout>
          {(layoutProps) => (
            <Switch>
              {loggedInRoutes.map(({ path, component }) => (
                <Route exact path={path} key={component}>
                  <AsyncPage page={component} event={currentEvent} {...layoutProps} />
                </Route>
              ))}

              <Route exact path="/:locale/:eventuri/not-found">
                <AsyncPage page="NotFound" event={currentEvent} appSettings={settings} />
              </Route>
              <Route exact path={initialURL.current}>
                <AsyncPage page="NotFound" event={currentEvent} appSettings={settings} />
              </Route>
            </Switch>
          )}
        </LoggedInLayout>
      ) : (
        <LoggedOutLayout
          currentEvent={currentEvent}
          settings={settings}
        >
          <Switch>
            <Route exact path={pathTo('login')}>
              <AsyncPage
                page="Login"
                eventUri={eventUri}
                accountId={accountId}
                welcomeMessage={loginWelcomeMessage[locale]}
                settings={settings}
              />
            </Route>

            <Route>
              <Redirect to={urlTo('login')} />
            </Route>
          </Switch>
        </LoggedOutLayout>
      )}
    </ThemeProvider>
  );
};

Component.propTypes = {
  currentEvent: CustomPropTypes.event.isRequired,
  settings: CustomPropTypes.appSettings.isRequired,
  currentAttendeeFetched: PropTypes.bool.isRequired,
  loggedInSuccessfully: PropTypes.bool.isRequired,
  callApi: PropTypes.func.isRequired,
  fetchAttendeeLeads: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  setActiveSubpage: PropTypes.func.isRequired,
  entities: CustomPropTypes.entities.isRequired,
};

const mapStateToProps = ({
  entities,
  fetchStatus: {
    currentAttendee: { success: currentAttendeeFetched },
    login: { success: loggedInSuccessfully },
  },
}) => ({
  currentAttendeeFetched,
  loggedInSuccessfully,
  entities,
});

export default compose(
  withInitialData(),
  connect(mapStateToProps, { callApi, logout, setActiveSubpage, fetchAttendeeLeads })
)(Component);

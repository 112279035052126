import PropTypes from 'prop-types';

const image = PropTypes.shape({
  filename: PropTypes.string,
  thumb: PropTypes.string,
  small: PropTypes.string,
  medium: PropTypes.string,
  large: PropTypes.string,
});

const formErrors = PropTypes.objectOf(PropTypes.shape({
  type: PropTypes.string,
  message: PropTypes.string,
  ref: PropTypes.shape({
    type: PropTypes.string,
  }),
}));

const reaction = PropTypes.shape({
  emoji: PropTypes.string,
  name: PropTypes.string,
  uuid: PropTypes.string,
});

const message = PropTypes.shape({
  uuid: PropTypes.string,
  body: PropTypes.string,
  attendee_id: PropTypes.string,
  username: PropTypes.string,
  avatar: PropTypes.string,
  timestamp: PropTypes.string,
});

const question = PropTypes.shape({
  uuid: PropTypes.string,
  body: PropTypes.string,
  timestamp: PropTypes.string,
});

const event = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  eventUri: PropTypes.string,
  whiteLabel: PropTypes.bool,
});

const appSettings = PropTypes.shape({
  id: PropTypes.string,
  darkTheme: PropTypes.bool,
  waitingImage: PropTypes.shape({
    filename: PropTypes.string,
    large: PropTypes.string,
  }),
  languageSelectorEnabled: PropTypes.bool,
  backgroundImage: PropTypes.shape({
    filename: PropTypes.string,
    large: PropTypes.string,
  }),
  icon: PropTypes.shape({
    filename: PropTypes.string,
    thumb: PropTypes.string,
  }),
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  logo: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
    small: PropTypes.string,
  }),
  modules: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.shape({
      en: PropTypes.string,
      es: PropTypes.string,
      fr: PropTypes.string,
    }),
    type: PropTypes.string,
    enabled: PropTypes.bool,
    customParams: PropTypes.shape({
      content: PropTypes.string,
      icon: PropTypes.string,
    }),
  })),
});

const meeting = PropTypes.shape({
  id: PropTypes.string,
  guid: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  host: PropTypes.shape({
    id: PropTypes.string,
  }),
  participant: PropTypes.shape({
    id: PropTypes.string,
  }),
  businessConferenceId: PropTypes.number,
  status: PropTypes.number,
  userMessage: PropTypes.string,
  isVirtual: PropTypes.bool,
});

const meetingRequest = PropTypes.shape({
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  slotId: PropTypes.number.isRequired,
});

const person = PropTypes.shape({
  id: PropTypes.string,
  participantId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  company: PropTypes.string,
  position: PropTypes.string,
  country: PropTypes.string,
  telephone: PropTypes.string,
  type: PropTypes.oneOf(['attendee', 'participant']),
  logo: image,
  headshot: image,
});

const attendee = PropTypes.shape({
  id: PropTypes.string,
});

const attendeeType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  attendeeTypeCustomFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
});

const participant = PropTypes.shape({
  id: PropTypes.string,
  profileContactName: PropTypes.string,
  profileDisplayName: PropTypes.string,
  blockedSlots: PropTypes.arrayOf(PropTypes.string),
});

const participantType = PropTypes.shape({
  id: PropTypes.string,
  canRequestMeetingToAttendeeTypes: PropTypes.arrayOf(PropTypes.number),
  agenda: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ),
});

const rating = PropTypes.shape({
  rate: PropTypes.number,
  participant: PropTypes.shape({
    id: PropTypes.string,
  }),
});

const deniedParticipant = PropTypes.shape({
  id: PropTypes.string,
  blacklistedParticipant: PropTypes.shape({
    id: PropTypes.string,
  }),
});

const recommendation = PropTypes.shape({
  businessConferenceParticipantId: PropTypes.string,
  score: PropTypes.number,
});

const survey = PropTypes.shape({
  id: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
});

const quiz = PropTypes.shape({
  id: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
});

const attendeeTypeCustomField = PropTypes.shape({
  id: PropTypes.string,
});

const customField = PropTypes.shape({
  id: PropTypes.string,
});

const workshop = PropTypes.shape({
  id: PropTypes.string,
  guid: PropTypes.string,
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  description: PropTypes.string,
  streamingType: PropTypes.string,
  streamingUrl: PropTypes.string,
  streamingEmbedCode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      en: PropTypes.string,
      es: PropTypes.string,
      fr: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.shape({
      displayName: PropTypes.string,
      embedCode: PropTypes.string,
    })),
  ]),
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  enabledEmotions: PropTypes.bool,
});

const attendeeWorkshops = PropTypes.shape({
  attendee: PropTypes.shape({
    id: PropTypes.string,
  }),
  workshop: PropTypes.shape({
    id: PropTypes.string,
  }),
});

const workshopCategory = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const eventFile = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  eventFile: PropTypes.string,
  entityType: PropTypes.string,
  entityId: PropTypes.number,
});

const speaker = PropTypes.shape({
  id: PropTypes.string,
  fullName: PropTypes.string,
  company: PropTypes.string,
  position: PropTypes.string,
  picture: PropTypes.shape({
    filename: PropTypes.string,
    large: PropTypes.string,
    small: PropTypes.string,
  }),
  banner: PropTypes.shape({
    filename: PropTypes.string,
    large: PropTypes.string,
  }),
});

const qaSession = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  anonymousQuestions: PropTypes.bool,
});

const qaQuestion = PropTypes.shape({
  id: PropTypes.string,
  question: PropTypes.string,
  likedBy: PropTypes.arrayOf(PropTypes.number),
  attendee: PropTypes.shape({
    id: PropTypes.string,
  }),
});

const note = PropTypes.shape({
  body: PropTypes.string,
  reviewed: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
});

const channel = PropTypes.shape({
  topic: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.string),
  ]),
  avatar: PropTypes.string,
  type: PropTypes.oneOf(['private', 'group', 'announcement']),
});

const filter = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  PropTypes.bool,
  PropTypes.shape({}),
]);

const entities = PropTypes.objectOf(
  PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  )
);

const conference = PropTypes.shape({
  id: PropTypes.string,
  meetingRejectionEnabled: PropTypes.bool,
  meetingRejectionDescriptionOptions: PropTypes.arrayOf(PropTypes.string),
  automaticScheduling: PropTypes.bool,
  blacklistEnabled: PropTypes.bool,
});

const meta = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({}),
      ])
    ),
  ])
);

const fetchStatus = PropTypes.objectOf(
  PropTypes.shape({
    success: PropTypes.bool,
    isFetching: PropTypes.bool,
    isFetchingFirstPage: PropTypes.bool,
    order: PropTypes.arrayOf(PropTypes.string),
    links: PropTypes.shape({
      prev: PropTypes.string,
      next: PropTypes.string,
    }),
    error: PropTypes.string,
  })
);

const sponsor = PropTypes.shape({
  logo: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
    large: PropTypes.string,
  }),
  bannerImage: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
    large: PropTypes.string,
  }),
  id: PropTypes.string,
  fileCategoryId: PropTypes.number,
  description: PropTypes.string,
  name: PropTypes.string,
  website: PropTypes.string,
  attendanceMode: PropTypes.string,
  category: PropTypes.shape({
    id: PropTypes.string,
  }),
});

const sponsorRepresentative = PropTypes.shape({
  id: PropTypes.string,
  active: PropTypes.bool,
  attendeeId: PropTypes.number,
});

const tags = PropTypes.shape({
  atributes: PropTypes.shape({
    name: PropTypes.string,
    parent_tag_id: PropTypes.number,
  }),
});

const activeSubpage = PropTypes.shape({
  module: PropTypes.string,
});

const hotspot = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  content: PropTypes.string,
  contentType: PropTypes.oneOf(['url', 'video', 'info', 'file']),
  coordinates: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
  }),
  sponsorId: PropTypes.number,
});

const product = PropTypes.shape({
  description: PropTypes.string,
  image: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
  }),
  name: PropTypes.string,
  price: PropTypes.number,
  purchaseLink: PropTypes.string,
});

const socialPost = PropTypes.shape({
  id: PropTypes.string,
  image: PropTypes.shape({
    filename: PropTypes.string,
    thumb: PropTypes.string,
    small: PropTypes.string,
    large: PropTypes.string,
  }),
  message: PropTypes.string,
  createdAt: PropTypes.string,
  likedBy: PropTypes.arrayOf(PropTypes.string),
  totalComments: PropTypes.number,
  postComments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  })),
  lastComment: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
  attendee,
});

const attendeeLead = PropTypes.shape({
  uuid: PropTypes.string,
  fullName: PropTypes.string,
  email: PropTypes.string,
  company: PropTypes.string,
  phone: PropTypes.string,
  notes: PropTypes.string,
  updatedAt: PropTypes.string,
  pendingSync: PropTypes.bool,
});

const viewedAttendess = PropTypes.objectOf(
  PropTypes.objectOf(PropTypes.bool)
);

export default {
  viewedAttendess,
  formErrors,
  reaction,
  message,
  question,
  conference,
  event,
  appSettings,
  meeting,
  meetingRequest,
  person,
  filter,
  attendee,
  attendeeType,
  attendeeTypeCustomField,
  customField,
  participant,
  participantType,
  rating,
  deniedParticipant,
  recommendation,
  survey,
  quiz,
  workshop,
  attendeeWorkshops,
  workshopCategory,
  eventFile,
  speaker,
  note,
  channel,
  entities,
  meta,
  sponsor,
  sponsorRepresentative,
  tags,
  activeSubpage,
  hotspot,
  product,
  socialPost,
  attendeeLead,
  qaSession,
  qaQuestion,
  fetchStatus,
};

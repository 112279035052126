import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import StarHalf from '@material-ui/icons/StarHalf';
import Star from '@material-ui/icons/Star';
import ReactRater from 'react-rater';
import clsx from 'clsx';

const useStarStyles = makeStyles((theme) => ({
  star: {
    pointerEvents: 'none',
  },
  inactiveStar: {
    color: theme.palette.lightGrey.dark,
  },
}));

const Stars = ({ willBeActive, isActiveHalf, isActive }) => {
  const classes = useStarStyles();

  if (willBeActive || isActive) return <Star classes={classes.start} color="primary" />;
  if (isActiveHalf) return <StarHalf classes={classes.start} color="primary" />;

  return <Star className={clsx(classes.star, classes.inactiveStar)} />;
};

Stars.propTypes = {
  willBeActive: PropTypes.bool,
  isActiveHalf: PropTypes.bool,
  isActive: PropTypes.bool,
};

Stars.defaultProps = {
  willBeActive: undefined,
  isActiveHalf: undefined,
  isActive: undefined,
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    '& > div': {
      cursor: 'pointer',
    },
  },
}));

const Rater = ({ value, onChange, disabled }) => {
  const classes = useStyles();

  return (
    <ReactRater
      className={classes.root}
      rating={Number(value)}
      interactive={!disabled}
      onRate={({ rating }) => onChange(rating)}
    >
      <Stars />
    </ReactRater>
  );
};

Rater.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Rater.defaultProps = {
  disabled: false,
};

export default Rater;
